<!-- 相关产品 -->
<template>
  <div id="relatedProduct">
    <div class="related-title">
      <div class="title">相关产品</div>
      <div class="line"></div>
    </div>
    <div class="related-product">
      <div class="related-box">
        <van-swipe
          :autoplay="3000"
          indicator-color="#0b847b"
          width="100%"
          height="100%"
        >
          <van-swipe-item v-for="(item, index) in swiperData" :key="index" @click="tapPages(item.summary)">
            <img :src="item.titleImage" />
            <div class="detail">
              {{ item.title }}
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  props: {
    swiperData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    tapPages(path) {
      // console.log(path);
      this.$router.push(path);
    },
  },
};
</script>
    
<style lang="scss">
#relatedProduct {
  .related-title {
    margin-bottom: 1rem;
    .title {
      text-align: center;
      font-size: 1.5rem;
      font-family: "SourceHanSansSC-Medium";
    }
    .line {
      width: 7rem;
      height: 0.375rem;
      background: #0b847b;
      margin: 0.5rem auto 1.5rem;
    }
  }

  .related-product {
    padding: 0 1.25rem 1.25rem;
    .related-box {
      // height: 15.625rem;
      .van-swipe__track {
        width: 100%;
        height: 100%;
        .van-swipe-item {
          position: relative;
          // height: 15.625rem;
          img {
            width: 100%;
            // height: 15.625rem;
          }
          .detail {
            text-align: center;
            padding-top: 1rem;
            font-size: 1rem;
            font-family: "OPlusSans3-Regular";
          }
        }
      }
      .van-swipe__indicators {
        bottom: 3.7rem;
        .van-swipe__indicator {
          background-color: #000;
        }
      }
    }
  }
}
</style>